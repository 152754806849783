<script setup lang="ts">
import Header from '@/components/main/Header.vue'
import Footer from '@/components/main/Footer.vue'
import SectionOrder from '@/components/section/SectionOrder.vue'
import ButtonTop from '@/components/button/ButtonTop.vue'
import SpinerLoader from './components/main/SpinerLoader.vue'
import stores from './storage/stores'
import { defineAsyncComponent, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { applyTheme, theme } from './event/theme/theme'
import { loadData } from './utils/loadData'
import { dataLoaded } from './event/dataLoaded'
import { watchersApp } from './utils/watchers/app/watchersApp'
import { formatStoreName } from '@/utils/store/formatStoreName'
import { cartBus } from '@/event/cartBus'
import { showSectionManagement } from './event/main/app/showSectionManagement'
import { detectClient } from './utils/config/detectClient'
import { clientConfig } from './config/clientConfig'
import { useHead } from '@vueuse/head'

const Error = defineAsyncComponent(() => import('@/components/error/Error.vue'))
const AlertTableQrCode = defineAsyncComponent(
    () => import('@/components/alert/AlertTableQrCode.vue')
)
const AlertClearBasket = defineAsyncComponent(
    () => import('@/components/alert/AlertClearBasket.vue')
)
const AlertDeleteElement = defineAsyncComponent(
    () => import('@/components/alert/AlertDeleteElement.vue')
)
const AlertBasketLogin = defineAsyncComponent(
    () => import('@/components/alert/AlertBasketLogin.vue')
)
const SectionOverlay = defineAsyncComponent(() => import('@/components/alert/SectionOverlay.vue'))
const Management = defineAsyncComponent(() => import('@/components/management/Management.vue'))

const route = useRoute()

const clientId = detectClient()
const client = Object.values(clientConfig).find((client) => client.hostname === clientId)

useHead({
    title: client?.title,
    meta: [{ name: 'description', content: client?.description }],
    link: [
        { rel: 'icon', href: `${client?.urlBack}${client?.favicon}` },
        { rel: 'apple-touch-icon', href: `${client?.urlBack}${client?.favicon}` },
        { rel: 'manifest', href: `${client?.urlBack}${client?.favicon}` }
    ]
})

watchersApp(route)

onMounted(async () => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
        applyTheme(savedTheme)
    }

    await loadData()
    // dataLoaded.value = 1

    let initialLoad = true

    document.addEventListener('visibilitychange', async () => {
        if (document.visibilityState === 'visible' && !initialLoad) {
            await loadData()
        }

        initialLoad = false
    })

    watch([() => route.name, () => dataLoaded.value], ([routeName, dataLoadedValue]) => {
        if (dataLoadedValue === 2) {
            const store = stores.state.storeData.name
            if (store) {
                const storeName = formatStoreName(store)
                const cartKey = `${storeName}_cart`

                if (routeName === 'success-payment') {
                    cartBus.value = []
                    localStorage.removeItem(cartKey)
                }
            }
        }
    })
})
</script>

<template>
    <div class="content-site" v-if="showSectionManagement === false">
        <div class="w-full h-full flex flex-col items-center">
            <Header />

            <SectionOverlay />

            <AlertDeleteElement v-if="theme == 'default'" />
            <AlertClearBasket v-if="theme == 'default'" />
            <AlertBasketLogin v-if="theme == 'default'" />

            <SectionOrder v-if="theme == 'default'" />

            <RouterView v-if="dataLoaded == 2" class="router-view-class" />
            <Error v-else-if="dataLoaded == 1" />
            <SpinerLoader v-else />
            <!-- <HamburgerLogo /> -->
            <!-- <SpinerLoader /> -->
        </div>
        <ButtonTop />

        <AlertTableQrCode />

        <Footer />
    </div>

    <Management v-else />
</template>

<style scoped>
/* */

.content-site {
    @apply flex flex-col items-center justify-between min-h-screen;
}

.router-view-class {
    @apply min-w-full;
}

/** */

@media screen and (min-width: 1030px) {
    .router-view-class {
        @apply min-w-[60rem] w-[61rem];
    }
}

@media screen and (min-width: 1280px) {
    .router-view-class {
        @apply min-w-[65rem] w-[66rem];
    }
}

@media screen and (min-width: 1500px) {
    .router-view-class {
        @apply min-w-[75rem] w-[75rem];
    }
}

@media screen and (min-width: 2000px) {
    .router-view-class {
        @apply min-w-[80rem] w-[80rem];
    }
}

/* .side-bar-active {
  opacity: 1;
} */
</style>
