<script setup lang="ts">
import ButtonBasketNavbar from '@/components/button/basket/ButtonBasketNavbar.vue'
import ButtonAccount from '@/components/button/account/ButtonAccount.vue'
// import ButtonAccountLogin from '@/components/button/account/ButtonAccountLogin.vue'
import LinkSidebarPath from '@/components/main/sidebar/LinkSidebarPath.vue'
// import IconArrowBottom from '../icons/iconEronor/IconArrowBottom.vue'
import { URL_IMAGE } from '@/config/constants'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { useRoute } from 'vue-router'
import { theme } from '@/event/theme/theme'
import { getCategoryName } from '@/utils/category/category'
import { toggleMenu } from '@/utils/toggle/toggleMenu'
import { menuVisibleBus } from '@/event/menuVisibleBus'
import { isAuthenticated } from '@/event/account/auth/authentification'
import { categoryIdBus } from '@/event/category/categoryIdBus'
import { showDropdownSidebarCategory } from '@/event/main/navbar/showDropdownSidebarCategory'
import { toggleDropdownSidebarCategory } from '@/utils/main/navbar/toggleDropdownSidebarCategory'
import { catalogueBase, logo } from '@/utils/main/navbar/statesNavbar'
import { watchersNavbar } from '@/utils/watchers/navbar/watchersNavbar'
import { configTelephone } from '@/storage/setting/configTelephone'
import { watcherNavbarMobileDesktop } from '@/utils/watchers/navbar/watcherNavbarMobileDesktop'
import { defineAsyncComponent, watch } from 'vue'
import { io, type Socket } from 'socket.io-client'
import stores from '@/storage/stores'
import { mostRecentOrder } from '@/event/order/mostRecentOrder'
import { waitingEvent } from '@/event/order/success/waitingEvent'
import { newOrder } from '@/event/order/data/newOrder'
import { lastOrderId, orderGet } from '@/storage/order/orderGet'
import { deliveryTimeOrder } from '@/event/order/data/deliveryTimeOrder'
import { newVisibleOrder } from '@/event/order/data/newVisibleOrder'
import { urlWebSocket } from '@/config/urlWebSocket'
import { showSectionManagement } from '@/event/main/app/showSectionManagement'

const SidebarSmoke = defineAsyncComponent(
    () => import('@/firstTheme/components/navbar/SidebarSmoke.vue')
)
const IconArrowBottom = defineAsyncComponent(
    () => import('../icons/iconEronor/IconArrowBottom.vue')
)
const ButtonAccountLogin = defineAsyncComponent(
    () => import('@/components/button/account/ButtonAccountLogin.vue')
)

const route = useRoute()

watchersNavbar(route)
watcherNavbarMobileDesktop(route)

const socket: Socket = io(urlWebSocket)

socket.connect()

watch(route, (newRoute, oldRoute) => {
    if (newRoute.name === 'dashboard-order' || newRoute.name === 'success-payment') {
        if (lastOrderId.value) {
            newOrder.value = Number(lastOrderId.value)
        }
    }
})

watch(
    () => newOrder.value,
    (orderNew, orderOld) => {
        if (orderNew !== 0) {
            const order_id = orderNew
            socket.emit('join', order_id)
            socket.off('orderStatus').on('orderStatus', (data) => {
                if (data.store_id === stores.state.storeData.store_id) {
                    if (data.order.order_info.order_id === mostRecentOrder.value?.order_id) {
                        mostRecentOrder.value = data.order.order_info
                        newVisibleOrder.value = null
                    } else {
                        newVisibleOrder.value = data.order.order_info
                    }

                    waitingEvent.value = true

                    if (data.order.order_info.delivery_time !== null) {
                        deliveryTimeOrder.value = data.order.order_info.delivery_time.replace(
                            ':',
                            'h'
                        )
                    }
                }
            })
        }
    }
)

watch(
    () => stores.getStoresData(),
    (newStore, oldStore) => {
        if (newStore) {
            const storeId = newStore.store_id

            if (!socket.connected) {
                socket.connect()
            }

            if (socket) {
                socket.emit('create', storeId)
                socket.off('switch').on('switch', (data) => {
                    console.log(data)
                    if (data.store_id === storeId) {
                        if (data.sw === 'web') {
                            if (data.value === 1) {
                                showSectionManagement.value = false
                            } else {
                                showSectionManagement.value = true
                            }
                        }
                        // const configWebOrder = stores.state.storeData.setting?.find(
                        //     (setting: { key: string }) => setting.key === 'config_web_order'
                        // )
                        // if (configWebOrder) {
                        //     configWebOrder.value = data.sw === 'web' && data.value !== 0
                        // }
                    }
                })
            }
        }
    }
)
</script>

<template>
    <nav class="">
        <div class="sidebar" :class="{ 'is-active': menuVisibleBus && theme === 'default' }">
            <div class="flex gap-2"></div>

            <div class="close-btn">
                <button class="" @click="toggleMenu">
                    <svg class="svg-cross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M16 8L8 16M8.00001 8L16 16"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
            <div class="div-link">
                <button
                    :class="{ 'h2-isactive': showDropdownSidebarCategory === true }"
                    class="uppercase border-b-4 border-t-4 border-slate-hover p-2 flex justify-between items-center"
                    @click="toggleDropdownSidebarCategory"
                >
                    <h2>Catégories</h2>
                    <IconArrowBottom :class="{ dropdownisactive: showDropdownSidebarCategory }" />
                </button>

                <div
                    class="dropdown-menu"
                    :class="{ 'dropdown-menu-active': showDropdownSidebarCategory }"
                >
                    <router-link
                        class="link-sidebar"
                        v-for="(category, index) in catalogueBase"
                        :key="index"
                        :to="{
                            name: 'catalog-product',
                            params: {
                                category_id: category.category_id.toString(),
                                category_name: getCategoryName(category).toString()
                            }
                        }"
                        @click="toggleMenu"
                    >
                        <div
                            class="text-link"
                            :class="{ 'text-link-active': category.category_id === categoryIdBus }"
                        >
                            {{ getCategoryName(category) }}
                        </div>
                    </router-link>
                </div>
            </div>

            <LinkSidebarPath />
        </div>

        <SidebarSmoke v-if="theme === 'smoke' || theme === 'smoke-white'" />

        <!---->

        <div class="overlay" :class="{ 'is-active': menuVisibleBus }" @click="toggleMenu"></div>

        <div id="section-navbar" class="section-navbar" :class="{ isflex: isNavbarVisible }">
            <section class="side-logo">
                <button class="menu-burger" @click="toggleMenu">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 22"
                        class=""
                        :class="{
                            'svg-burger-smoke-white': theme === 'smoke-white',
                            'svg-burger': theme === 'default' || theme === 'smoke'
                        }"
                    >
                        <g clip-path="url(#clip0_429_11066)">
                            <path
                                d="M3 6.00092H21M3 12.0009H21M3 18.0009H21"
                                stroke-width="2.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_429_11066">
                                <rect width="24" height="24" transform="translate(0 0.000915527)" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>

                <a class="link-phone" v-if="configTelephone" :href="`tel:${configTelephone}`">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="svg-phone"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M2.00589 4.54166C1.905 3.11236 3.11531 2 4.54522 2H7.60606C8.34006 2 9.00207 2.44226 9.28438 3.1212L10.5643 6.19946C10.8761 6.94932 10.6548 7.81544 10.0218 8.32292L9.22394 8.96254C8.86788 9.24798 8.74683 9.74018 8.95794 10.1448C10.0429 12.2241 11.6464 13.9888 13.5964 15.2667C14.008 15.5364 14.5517 15.4291 14.8588 15.0445L15.6902 14.003C16.1966 13.3687 17.0609 13.147 17.8092 13.4594L20.8811 14.742C21.5587 15.0249 22 15.6883 22 16.4238V19.5C22 20.9329 20.8489 22.0955 19.4226 21.9941C10.3021 21.3452 2.65247 13.7017 2.00589 4.54166Z"
                        />
                    </svg>
                    <p>{{ configTelephone }}</p>
                </a>
            </section>

            <div class="logo">
                <router-link :to="{ path: '/' }">
                    <img
                        v-if="logo"
                        width="100px"
                        :src="`${URL_IMAGE}${logo}`"
                        alt=""
                        loading="lazy"
                        rel="preload"
                    />
                    <img
                        v-else
                        width="80px"
                        src="@/components/icons/eronor/icone_eronor.png"
                        alt=""
                    />
                </router-link>
            </div>

            <section class="side-logo">
                <!-- <button>
          <svg viewBox="0 0 22 22" class="svg-search" fill="none">
            <path
              d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z"
              stroke-width="1.5"
            />
          </svg>
        </button> -->
                <ButtonAccount v-if="!isAuthenticated" />
                <ButtonAccountLogin v-if="isAuthenticated" />

                <ButtonBasketNavbar />
            </section>
        </div>
    </nav>
</template>

<style scoped>
.text-link {
    @apply py-2;
}

.text-link:hover {
    @apply text-lime-eronor bg-slate-hover rounded-sm;
}

.text-link-active {
    @apply text-lime-eronor bg-slate-hover rounded-sm;
}

.h2-isactive {
    @apply border-b-lime-eronor border-t-lime-eronor;
}

.dropdownisactive {
    @apply fill-lime-eronor;
    transform: rotate(180deg);
}

.link-sidebar {
    @apply block border-b-[1px] border-slate-hover py-1 w-full;
}

.div-link {
    @apply w-full relative flex flex-col py-4 text-center uppercase font-medium;
}

.dropdown-menu {
    @apply z-10;
    max-height: 0;
    overflow: hidden;
    transform: scaleY(-10px);
    transform-origin: top;
    transition:
        max-height 0.3s ease,
        opacity 0.3s ease,
        transform 0.3s ease;
    opacity: 0;
}

.dropdown-menu-active {
    transform: translateY(0);
    opacity: 1;
    max-height: 100%;
}

.svg-cross {
    @apply stroke-white-eronor w-10;
}

.close-btn {
    @apply w-full flex justify-end items-center;
}

.close-btn:hover .svg-cross {
    @apply stroke-limedark-eronor;
}

.close-btn h2 {
    @apply font-semibold;
}

.overlay {
    @apply fixed top-0 left-0 w-full h-full z-20 opacity-0 invisible;
    background-color: rgba(0, 0, 0, 0.7);
    transition:
        opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
}

.overlay.is-active {
    @apply opacity-100 visible;
}

.is-active {
    @apply opacity-100;
}

.sidebar {
    @apply flex flex-col items-center fixed py-3 px-3 top-0 left-0 h-full w-72 bg-bg-eronor red:bg-red-eronor green:bg-green-600 blue:bg-blue-600 z-30 -translate-x-full;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
}

.sidebar.is-active {
    @apply translate-x-0;
}

.link-phone {
    @apply hidden;
}

/* hover */

/* svg */

.svg-basket {
    @apply w-10 fill-white-eronor stroke-white-eronor;
}

.svg-basket:hover {
    @apply fill-limedark-eronor stroke-limedark-eronor;
}

.svg-search {
    @apply w-10 stroke-white-eronor;
}

.svg-search:hover {
    @apply stroke-limedark-eronor;
}

.svg-burger {
    @apply w-6 stroke-white-eronor;
}

.svg-burger:hover {
    @apply stroke-limedark-eronor;
}

.svg-burger-smoke-white {
    @apply w-6 stroke-black;
}

/* navbar */

.side-logo {
    @apply flex bg-transparent items-center gap-2;
}

.section-navbar.isflex {
    @apply flex relative;
}

.section-navbar {
    @apply hidden py-3 px-6 mx-auto gap-3 justify-between items-center container;
}

@media screen and (min-width: 800px) {
    .section-navbar {
        @apply hidden mx-auto px-8;
    }
}

@media screen and (min-width: 1030px) {
    .section-navbar {
        @apply w-[60rem];
    }

    .logo {
        @apply pl-20;
    }

    .link-phone {
        @apply flex flex-row gap-2;
    }

    .link-phone:hover {
        @apply fill-limedark-eronor text-limedark-eronor;
    }

    .link-phone:hover .svg-phone {
        @apply fill-limedark-eronor;
    }

    .svg-phone {
        @apply fill-lime-eronor w-5;
    }

    .menu-burger {
        @apply hidden;
    }
}

@media screen and (min-width: 1500px) {
    .section-navbar {
        @apply w-[75rem];
    }
}
</style>
